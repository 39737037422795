.profile-card {
  text-align: center;
  background-color: hsl(213, 19%, 18%);
  border-radius: 8px;
  padding: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 4px;
}

.profile-img {
  border-radius: 50%;
  width: 112px;
  border: 4px solid hsl(25, 97%, 53%);
  margin-bottom: 8px;
}

p {
  color: hsl(216, 12%, 54%);
  font-size: 24px;
  font-weight: 700;
}

span {
  color: white;
  font-size: 18px;
  font-weight: 400;
}
